.menu_main3 {
  height: 100%;
  width: 0%;
  position: absolute;
  padding: 0px;
  z-index: 90;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  z-index: 5;
}

.menu_main3_bg {
  height: 200%;
  width: 100%;
  position: absolute;
  padding: 0px;
  background-color: #ffe0dc;
  z-index: 89;
  z-index: 5;
  overflow: hidden;
}

.menu_main3_bg2 {
  height: 200%;
  width: 100%;
  position: absolute;
  padding: 0px;
  background-color: black;
  z-index: 90;
  z-index: 5;
  overflow: hidden;
}
.menu3 {
  display: flex;
  font-family: "TIMMONS NY 2.0";
  font-style: normal;
  font-weight: 100;
  font-size: 5em;
  justify-content: center;
  margin: 0px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  z-index: 7;
}

.volet1 {
  align-content: center;
  height: 40vh;
  padding: 0px;
  position: absolute;
  width: 40vw;
  z-index: 5;
  overflow: hidden;
  background-color: #ffe0dc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.volet2 {
  align-content: center;
  height: 40vh;
  padding: 0px;
  position: absolute;
  width: 40vw;
  z-index: 6;
  overflow: hidden;
  background-color: #7d938a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  border-left: 0.3em solid white;
  border-right: 0.3em solid white;
}

.menu-txt-flex {
  align-content: center;
  height: 40vh;
  padding: 0px;
  position: absolute;
  width: 40px;
  z-index: 5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.menu-txt-overflow {
  align-self: center;
  color: #ffe0dc;
  font-family: ApercuProMono;
  font-size: 1em;
  justify-content: center;
  margin: 1px;
  opacity: 100%;
  width: max-content;
  height: max-content;
}

.cat3 {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
}

.cat4 {
  color: #7d938a;
}

.view_menu2 {
  visibility: visible;
}

.menu-desktop {
  display: flex; /* pour le menu en mode desktop */
  width: 100%;
  max-width: 95vw;
  margin-bottom: 0px;
  font-family: "ApercuProMono";
  font-style: normal;
  font-weight: 100;
  flex-direction: column;
  align-items: flex-end;
}

.hide_menu2 {
  -webkit-text-fill-color: white;
}
.hide_menu2:hover {
  -webkit-text-fill-color: rgb(240, 97, 30);
}
