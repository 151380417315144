.menu_main3 {
  height: 100%;
  width: 0%;
  z-index: 90;
  z-index: 5;
  flex-direction: column;
  place-content: center;
  padding: 0;
  position: absolute;
}

.menu_main3_bg {
  height: 200%;
  width: 100%;
  z-index: 89;
  z-index: 5;
  background-color: #ffe0dc;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.menu_main3_bg2 {
  height: 200%;
  width: 100%;
  z-index: 90;
  z-index: 5;
  background-color: #000;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.menu3 {
  z-index: 7;
  flex-direction: column;
  place-content: center;
  align-items: center;
  margin: 0;
  font-family: "TIMMONS NY 2.0";
  font-size: 5em;
  font-style: normal;
  font-weight: 100;
  display: flex;
}

.volet1 {
  height: 40vh;
  width: 40vw;
  z-index: 5;
  background-color: #ffe0dc;
  flex-direction: column;
  place-content: center;
  align-self: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.volet2 {
  height: 40vh;
  width: 40vw;
  z-index: 6;
  background-color: #7d938a;
  border-left: .3em solid #fff;
  border-right: .3em solid #fff;
  flex-direction: column;
  place-content: center;
  align-self: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.menu-txt-flex {
  height: 40vh;
  width: 40px;
  z-index: 5;
  flex-direction: column;
  place-content: center;
  align-self: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.menu-txt-overflow {
  color: #ffe0dc;
  opacity: 1;
  width: max-content;
  height: max-content;
  justify-content: center;
  align-self: center;
  margin: 1px;
  font-family: ApercuProMono;
  font-size: 1em;
}

.cat3 {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
}

.cat4 {
  color: #7d938a;
}

.view_menu2 {
  visibility: visible;
}

.menu-desktop {
  width: 100%;
  max-width: 95vw;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0;
  font-family: ApercuProMono;
  font-style: normal;
  font-weight: 100;
  display: flex;
}

.hide_menu2 {
  -webkit-text-fill-color: white;
}

.hide_menu2:hover {
  -webkit-text-fill-color: #f0611e;
}

/*# sourceMappingURL=index.0c003a92.css.map */
